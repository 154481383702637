import { Button, Loading } from "@nextui-org/react";
import { useState } from "react";
import { TbFileExport } from "react-icons/tb";
import * as Xlsx from "xlsx";

const ExportInventory = ({ currentList }) => {
  const [exportLoading, setExportLoading] = useState(false);

  const downloadExcel = () => {
    setExportLoading(true);

    let strucuredData = [];
    currentList.iphones?.forEach((doc) => {
      doc?.variants?.forEach((d) => {
        strucuredData.push({
          name: doc.name,
          pid: doc?.pid,
          price: d?.price,
          qty: d?.quantity,
          variant: d?.storage,
        });
      });
    });

    currentList.ipods?.forEach((doc) => {
      strucuredData.push({
        name: doc.name,
        pid: doc?.pid,
        price: doc?.price,
        qty: doc?.quantity,
        variant: "Default",
      });
    });

    currentList.iwatches?.forEach((doc) => {
      doc?.variants?.forEach((d) => {
        strucuredData.push({
          name: doc.name,
          pid: doc?.pid,
          price: d?.price,
          qty: d?.quantity,
          variant: `${d?.size} ${d?.type}`,
        });
      });
    });

    const data = strucuredData
      .sort((a, b) => {
        return ("" + a.name).localeCompare(b.name);
      })
      .map((doc) => {
        return {
          Name: doc.name,
          Variant: doc?.variant,
          ID: doc?.pid,
          Price: doc?.price,
          Qty: doc?.qty,
        };
      });
    const workSheet = Xlsx.utils.json_to_sheet(data);
    const workBook = Xlsx.utils.book_new();
    Xlsx.utils.book_append_sheet(workBook, workSheet, "Sheet1");
    Xlsx.writeFile(workBook, `inventory.xlsx`);
    setExportLoading(false);
  };

  return (
    <>
      {" "}
      <Button
        auto
        size="sm"
        color="success"
        icon={
          exportLoading ? (
            <Loading type="spinner" color="white" />
          ) : (
            <TbFileExport />
          )
        }
        onClick={downloadExcel}
        css={{ mr: 3 }}
      />
    </>
  );
};

export default ExportInventory;
